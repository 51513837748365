import { useState } from 'react';
import {
  Button,
  Flex,
  RadioProps,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from '@xstate5/react';

import { RadioButton } from '@arena-labs/strive2-ui';

import { useDiscoveredDevices, useSetupActor } from './hooks';
import { SetupNoDevices } from './setup-no-devices';

export function SetupMultipleDevices() {
  const devices = useDiscoveredDevices();
  const setupActor = useSetupActor();
  const isPairing = useSelector(setupActor, (state) => state.context.isPairing);
  const [selected, setSelected] = useState<string>();
  const { getRadioProps } = useRadioGroup({
    name: 'deviceId',
    onChange: setSelected,
  });

  const submit = useMutation({
    mutationFn: async (deviceId: string) => {
      const device = devices.get(deviceId);
      if (!device) return;
      await setupActor.send({ type: 'Pair', device });
    },
  });

  if (devices.size === 0) return <SetupNoDevices />;

  return (
    <Flex
      direction="column"
      gap="3"
      px="6"
      py="4"
      pb="calc(env(safe-area-inset-bottom) + var(--chakra-space-4))"
      h="full"
    >
      <Text textStyle="h3">Multiple StriveWare Found!</Text>
      <Text>Please select a device to continue</Text>
      {Array.from(devices).map(([deviceId, device]) => (
        <RadioButton
          key={deviceId}
          variant="outline-selected"
          w="full"
          {...(getRadioProps({ value: deviceId }) as RadioProps)}
          radio={{
            textAlign: 'left',
            bg: 'white',
            color: 'neutral.700',
          }}
        >
          Device ID: {device.name}
        </RadioButton>
      ))}
      {selected ? (
        <Button
          isLoading={isPairing}
          loadingText="Connecting..."
          onClick={() => submit.mutate(selected)}
          mt="auto"
        >
          Pair
        </Button>
      ) : null}
    </Flex>
  );
}
