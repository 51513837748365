import { isToday } from 'date-fns';

import { useAciMachine } from '@arena-labs/strive2-data';
import { $API } from '@strive/api';

export function useNeedsSleepNeedsACI() {
  const { data: progress } = $API.useGetProgressData();

  const [state] = useAciMachine();
  const { aciState, aciResponse } = state.context;
  const isSkipped = aciResponse?.skipped || aciState.skipped;
  const isComplete =
    aciResponse &&
    isToday(aciResponse.created_at) &&
    !aciResponse.skipped &&
    Boolean(aciResponse.daily);

  const aciLoggedToday = isSkipped || isComplete;

  const needsANightsSleep =
    progress?.results.length === 1 &&
    (progress.results?.[0]?.start === null ||
      progress.results?.[0]?.start === undefined);

  return [needsANightsSleep, aciLoggedToday];
}
